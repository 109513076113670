<template>
  <footer class="mpt-0">
    <div class="container">
      <div class="row">
        <!-- Column 4 Start -->
        <div class="col-md-3 col-sm-6 col-12">
          <h3>Contact</h3>
          <div class="mt-25">
            <h4 class="text-light">{{ about.name }}</h4>
            <p><strong>Address:</strong> {{ about.address }}</p>
            <p><strong>Tel:</strong> {{ about.hotline_one }}, {{ about.hotline_two }}, {{ about.hotline_three }}</p>
            <p> <strong>Email:</strong> {{ about.email }}</p>
            <p class="text-md-start fw-bold mt-4">
              <span class="primary-color position-static">Total Visitor:</span>
              <!-- hitwebcounter Code START -->
              <a href="https://www.hitwebcounter.com" target="_blank"> <img style="width: 100px" src="https://hitwebcounter.com/counter/counter.php?page=9974707&style=0001&nbdigits=5&type=page&initCount=0" title="Counter Widget" Alt="Visit counter For Websites" border="0" /></a>
            </p>
          </div>
        </div>
        <!-- Column 4 End -->

        <!-- Column 2 Start -->
        <div class="col-md-4 col-sm-6 col-12">
          <h3>Quick Links</h3>
          <div class="row" style="margin-top: 20px;">
            <div class="col-md-6" v-for="(service, i) in services" :key="i">
              <ul class="footer-list m-0">
                <li>
                  <router-link v-if="service.link == null" :to="`/service_details/${service.id}`">{{ service.name }}</router-link>
                  <a v-else :href="service.link" target="_blank">{{ service.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Column 2 End -->

        <!-- Column 2 Start -->
        <div class="col-md-2 col-sm-6 col-12">
          <h3>Support Links</h3>
          <ul class="footer-list">
            <li><router-link :to="{ name: 'package' }">Packages</router-link></li>
            <li><router-link :to="{ name: 'coverageareas' }">Our Pop</router-link></li>
            <li><router-link :to="{ name: 'BillPayPage' }">Bill Pay</router-link></li>
            <li><router-link :to="{ name: 'about' }">About</router-link></li>
            <li><router-link :to="{ name: 'offers' }">Offer</router-link></li>
            <li><router-link :to="{ name: 'contact' }">Contact</router-link></li>
          </ul>
        </div>
        <!-- Column 2 End -->

        <!-- Column 1 Start -->
        <div class="col-md-3 col-sm-6 col-12">
          <h3>Popular Products</h3>
          <ul class="footer-list">
            <!-- <li><router-link :to="{ name: 'ShopProductPage' }">Wireless Router</router-link></li> -->
            <!-- <li><router-link :to="{ name: 'ShopProductPage' }">Update Anti-virus Softwares</router-link></li> -->
            <!-- <li><router-link :to="{ name: 'ShopProductPage' }">Powerful Switch Cable</router-link></li> -->
            <!-- <li><router-link :to="{ name: 'ShopProductPage' }">Wireless USB Adapter/Card</router-link></li> -->
            <li><router-link :to="{ name: 'ShopProductPage' }">My Shop</router-link></li>
            <li><router-link :to="{ name: 'ContactMessage' }">Internet Help</router-link></li>
          </ul>
        </div>
        <!-- Column 1 End -->

        <!-- Social Links Column -->
      </div>

      <div class="footer-bar">
        <div class="row">
          <div class="col-md-4 order-md-2">
            <!-- <p class="text-md-center fw-bold">
              <span class="primary-color position-static">Total Visitor:</span>
              <a href="https://www.hitwebcounter.com" target="_blank"> <img style="width: 100px" src="https://hitwebcounter.com/counter/counter.php?page=9974707&style=0001&nbdigits=5&type=page&initCount=0" title="Counter Widget" Alt="Visit counter For Websites" border="0" /></a>
            </p> -->
          </div>
          <div class="col-md-4 order-md-1">
            <p><span class="primary-color position-static">Savar Netcity</span> © {{ date }}. All Rights Reserved.</p>
          </div>
          <div class="col-md-4 order-3">
            <div class="text-md-end">
              <ul>
                <!-- <li><a href="https://www.facebook.com/netcityfamily/" target="_blank"><i
                                        class="fa fa-facebook-square"></i></a></li> -->
                <!--<li><a href="#" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fa fa-pinterest"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fa fa-skype"></i></a></li>-->
                <li>
                  <span>Developed By - </span><a href="https://aritechbd.com"><span style="color: #fff">Ari Tech & Engineering</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Bar End -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  props: ['services', 'about'],
  data() {
    return {
      date: '',
    }
  },
  created() {
    let d = new Date();
    this.date = d.getFullYear();
  }
};
</script>

<style scoped>
footer {
  background-color: #111828 !important;
}

footer p {
  color: #fff;
}

.footer-list li a {
  color: #fff;
}

ul.footer-social-icons {
  background-color: #000;
}

.social-link-facebook {
  margin-top: 25px;
  background-color: #0b0e1d;
}

.social-link-youtube {
  margin-top: 10px;
  background-color: #0b0e1d;
}

.social-link-linkedin {
  margin-top: 10px;
  background-color: #0b0e1d;
}

.social-link-facebook i {
  font-size: 31px;
  padding: 4px;
  background-color: #fff;
  color: #395ba2;
}

.social-link-youtube i {
  font-size: 31px;
  padding: 4px;
  background-color: #fff;
  color: red;
}

.social-link-linkedin i {
  font-size: 31px;
  padding: 4px;
  background-color: #fff;
  color: red;
}

.footer-list li a {
  text-decoration: none;
}

.footer-bar li a {
  text-decoration: none;
}
</style>
