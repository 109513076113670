<template>
  <!-- Top-Bar START -->
  <div id="top-bar" class="hidden-sm-down">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12 d-flex text-light">
          <div class="p-2" style="font-size: 13px;">Welcome to {{ commonData.name }}</div>
          <div class="p-2" style="font-size: 13px;"><i class="fa fa-phone text-info pe-2"></i>{{ commonData.hotline_two }}</div>
          <div class="p-2" style="font-size: 13px;"><i class="fa fa-envelope text-info pe-2"></i>{{ commonData.email }}</div>
        </div>
        <div class="col-md-5 col-12 d-flex justify-content-end">
          <div class="p-2 px-3">
            <a href="" v-on:click.prevent="openPdf" target="_blank" style="text-decoration: none;text-transform: uppercase;font-size: 13px;color:#eee">BTRC Approved Tariff</a>
          </div>
          <div class="p-2 px-3">
            <router-link :to="{ name: 'SupportPage' }" style="text-decoration: none;text-transform: uppercase;font-size: 13px;color:#eee">Support</router-link>
          </div>
          <div class="p-2 px-2">
            <a :href="commonData.facebook" target="_blank"><i class="fa fa-facebook-square text-primary" style="font-size: 18px;"></i></a>
          </div>
          <div class="p-2 px-2">
            <a :href="commonData.youtube" target="_blank"><i class="fa fa-youtube-play text-danger" style="font-size: 18px;"></i></a>
          </div>
          <div class="p-2 px-2">
            <a :href="commonData.instagram" target="_blank"><i class="fa fa-instagram text-danger" style="font-size: 18px;" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4 col-12">
            <ul class="social-icons hidden-md-down">
              <li>
                <a href="" v-on:click.prevent="openPdf" target="_blank" style="text-decoration: none;text-transform: uppercase;">BTRC Approved Tariff</a>
              </li>
              <li>
                <router-link :to="{ name: 'SupportPage' }" style="text-decoration: none;text-transform: uppercase;">Support</router-link>
              </li>
              <li>
                <a href="https://www.facebook.com/netcityfamily/" target="_blank">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
            </ul>
          </div> -->
    </div>
  </div>
  <!-- Top-Bar END -->

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <router-link class="navbar-brand" :to="{ name: 'home' }">
        <img :src="baseUrl + commonData.image" class="main-logo" style="height: 50px; width: 130px" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'package' }">Packages</router-link></li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="" id="subMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Our Service </a>
            <ul class="dropdown-menu" aria-labelledby="subMenu">
              <span v-for="(item, i) in  testimonias " :key="i">
                <li v-if="item.link == null">
                  <router-link class="dropdown-item" :to="`/service_details/${item.id}`">{{ item.name }}</router-link>
                </li>
                <li v-else>
                  <a class="dropdown-item" :href="item.link" target="_blank">{{ item.name }}</a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
              </span>
            </ul>
          </li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'coverageareas' }">Our Pop</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'BillPayPage' }">Bill Pay</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'about' }">About</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'offers' }">Offer</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'ShopProductPage' }">Shop</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{ name: 'contact' }">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import axios from "axios";
export default {
  name: "NavbarMenu",
  props: ["testimonias", "commonData"],
  data() {
    return {

      baseUrl: this.$appUrl,
      // testimonias: [],
    };
  },
  created() {
    // this.getTestimonial();
  },
  methods: {

    openPdf() {
      window.open(`${this.baseUrl}uploads/pdf/savar_net_city.pdf`);
    },
    // async getTestimonial() {
    //   try {
    //     const response = await axios.get("get-testimonial");
    //     this.testimonias = response.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
};
</script>

<style scoped>
.navbar-light .navbar-nav .nav-link {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000;
  text-transform: uppercase;
}

/*!* Dropdown menu *!*/
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
}

/* Show the submenu on hover */

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  list-style: none;
  padding: 0px;
}

@media (max-width: 767px) {
  .slider-content {
    padding: 0px 0px !important;
  }

  span {
    font-size: 17px !important;
    position: relative;
    top: 0px !important;
  }
}
</style>
