<template>
  <header>
    <navbar-menu :commonData="commonData" :testimonias="services"></navbar-menu>
  </header>

  <router-view :key="$route.fullPath" />

  <chat-page></chat-page>

  <footer-section :services="services" :about="commonData"></footer-section>
</template>
<script>
import axios from "axios";
import NavbarMenu from "./components/NavbarMenu.vue";
import FooterSection from "./components/FooterSection.vue";
import ChatPage from "./components/ChatPage.vue";
export default {
  name: "App",
  components: {
    NavbarMenu,
    FooterSection,
    ChatPage
  },
  data() {
    return {
      commonData: {},
      services: [],
      baseUrl: this.$appUrl,
    };
  },
  created() {
    this.getHeadData();
    this.getServiceData();
  },
  methods: {
    async getHeadData() {
      try {
        const response = await axios.get("get-about-data");
        this.commonData = response.data.about;
      } catch (error) {
        console.error(error);
      }
    },
    async getServiceData() {
      this.id = this.$route.params.id;
      axios.get("get-testimonial").then((res) => {
        this.services = res.data;
      });
    },
  },
};
</script>

<style></style>
