<template>
  <div class="chat-icon" id="chatIcon" v-on:click.prevent="isActive = !isActive">
    <i class="flaticon-note fa fa-envelope"> Chat</i>
  </div>
  <div class="chat-box" id="chatBox" style="display: none;" :style="{ display: isActive ? 'block' : 'none' }">
    <div class="chat-header">
      <h2>Message</h2>
      <div class="close-icon" id="closeIcon">
        <span class="icon-close" style="font-size: 20px !important;" v-on:click.prevent="isActive = !isActive">X</span>
      </div>
    </div>
    <div class="chat-body" id="chatBody"></div>
    <form id="messageForm" v-on:submit.prevent="saveData">
      <input type="text" id="nameInput" v-model="message.name" placeholder="Your Name" required />
      <input type="email" id="emailInput" v-model="message.email" placeholder="Your Email" required />
      <input type="text" id="subjectInput" v-model="message.subject" placeholder="Subject" required />
      <textarea id="messageInput" v-model="message.message" placeholder="Type your message..." required></textarea>
      <button type="submit" id="sendMessageBtn">Send</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "ChatPage",
  props: {
    services: Array,
  },
  data() {
    return {
      message: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      isActive: false,
    }
  },
  methods: {
    saveData() {
      if (this.message.name == '') {
        alert('Name is required!')
        return
      }
      if (this.message.message == '') {
        alert('Message is required!')
        return
      }

      axios.post("save-message-chat", this.message).then(res => {
        alert(res.data.message)
        if (res.data.status) {
          this.clearForm();
        }
      })

    },
    clearForm() {
      this.message = {
        name: '',
        email: '',
        subject: '',
        message: '',
      }
    }

  }
};
</script>
<style scoped>
.message-container {
  position: relative;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
  cursor: pointer;
}

.chat-icon .flaticon-note {
  /* background: linear-gradient(to right, #B77229, #795D24) !important; */
  background-color: #FD8129 !important;
  border: 2px solid #ffffff;
  padding: 10px 23px;
  border-radius: 50px;
  cursor: pointer;
  color: #ffffff;
  font-size: 27px;
}


.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  width: 300px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  z-index: 999;
}

.chat-header {
  /* background: linear-gradient(to right, #B77229, #795D24) !important; */
  background-color: #FD8129 !important;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h2 {
  color: #fff !important;
}

.chat-body {
  padding: 20px;
  /* height: 200px; */
  overflow-y: auto;
}

.chat-footer {
  display: flex;
  padding: 10px;
  align-items: center;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

/* Style for the Send button */
button[type="submit"] {
  width: 100%;
  /* background: linear-gradient(to right, #B77229, #795D24) !important; */
  background-color: #FD8129 !important;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="button"]:hover {
  background: #fd8129d4 !important;
}

.icon-close {
  background-color: #fff;
  padding: 6px 8px;
  color: #B77229 !important;
  border-radius: 7px;
  cursor: pointer;
}
</style>
